import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button, HeroTimeLocationGrid } from '../../components'

import { SContainer } from './HeroSectionPartners.styled'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import preventClick from 'utils/preventClick'
import { AppRoute } from 'const'
import { setContactModalVisibility } from '../../state/reducers/app/appActions'
import { useDispatch } from 'react-redux'
import { useBreakpoint } from '../../modules/breakpoint'

function HeroSectionPartners() {
  const { formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()
  const dispatch = useDispatch()
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint.lg
  const openCallpageWidget = () => {
    if (typeof window === 'undefined') return
    window.callpage('api.widget.open')
  }
  const openContactModal = () => {
    dispatch(setContactModalVisibility(true))
  }

  const openContactModalOrCallpage = () => {
    window.callpage ? openCallpageWidget() : openContactModal()
  }

  return (
    <SContainer>
      <div className="content">
        <p className="preheading">
          {formatMessage({
            id: 'heroAdditionalSubHeading',
          })}
        </p>
        <h1 className="heading">
          {formatMessage(
            { id: 'heroPartnersHeading' },
            {
              span: (chunk) => <span>{chunk}</span>,
              br: <br />,
            }
          )}
        </h1>
        <p className="cta">
          {formatMessage({
            id: 'heroHomePageCta',
          })}
        </p>
        <HeroTimeLocationGrid />
        <Button height="52px" onClick={openContactModalOrCallpage}>
          {formatMessage({
            id: 'joinPartnersButton',
          })}
          <i className="ri-arrow-right-line" />
        </Button>
        <hr className="divider-horizontal" />
        <p className="button-text">
          {formatMessage({
            id: 'heroPartnersButtonText',
          })}
        </p>
        <Button
          secondary="true"
          onClick={preventClick}
          height={isMobile ? '52px' : '40px'}
          fontSize={isMobile ? '20px' : '16px'}
          as={Link}
          to="#"
          className="disable-secondary-button"
          // to={localizeRouteKey(AppRoute.BuyTicket)}
        >
          {formatMessage({
            id: 'getTicketNowButton',
          })}
          <i className="ri-arrow-right-line" />
        </Button>
      </div>
    </SContainer>
  )
}

export default HeroSectionPartners
