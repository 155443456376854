import React from 'react'
import { useIntl } from 'react-intl'

import { TicketSummary, Loader } from 'components'
import { SUserTickets, STicketStatus } from './UserTickets.styled'
import AuthenticatedLink from 'components/AuthenticatedLink/AuthenticatedLink'
import config from 'const/config'

function UserTickets({ tickets }) {
  const { formatMessage, locale } = useIntl()

  const renderTicketStatus = (ticket) => {
    switch (ticket.payment_status) {
      case 'COMPLETED':
        return (
          <STicketStatus as="p">
            <AuthenticatedLink
              url={`${config.CE_API_URL}/participant/tickets/${ticket.hash}/download`}
              filename={`${
                locale === 'pl'
                  ? ticket.ticket.place
                  : ticket.ticket.place_en || ticket.ticket.place
              } - Code Europe 2024`}
              className="download"
            >
              {formatMessage({ id: 'user.ticketStatusSuccess' })}
            </AuthenticatedLink>
          </STicketStatus>
        )
      case 'CANCELED':
        return (
          <STicketStatus error>
            {formatMessage({ id: 'user.ticketStatusCanceled' })}
          </STicketStatus>
        )
      default:
      case 'PENDING':
        return (
          <STicketStatus waiting>
            {formatMessage({ id: 'user.ticketStatusPending' })}
          </STicketStatus>
        )
    }
  }

  const { loading, data } = tickets
  return (
    <SUserTickets>
      {loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : null}
      {data.map((ticket) => {
        return (
          <>
            {ticket.ticket ? (
              <div key={ticket.id} className="ticket">
                <TicketSummary
                  simple
                  {...{
                    ticket: {
                      date_start: ticket.ticket.date_start,
                      date_end: ticket.ticket.date_end,
                      place: ticket.ticket.place,
                      place_en: ticket.ticket.place_en,
                    },
                  }}
                />
                {renderTicketStatus(ticket)}
              </div>
            ) : null}
          </>
        )
      })}
      <div className="help">
        <p>{formatMessage({ id: 'user.ticketsNeedHelpLabel' })}?</p>
        <p>
          {formatMessage({ id: 'user.ticketsNeedHelp' })}:{' '}
          <a href="mailto:kontakt@codeeurope.pl">kontakt@codeeurope.pl</a>
        </p>
      </div>
    </SUserTickets>
  )
}

export default UserTickets
